export default [
    "ligneMaterielCU", 
    "ligneMaterielQte",
    "ligneSousTraitanceCU",
    "ligneSousTraitanceQte",
    "ligneMainOeuvreLocaleCU",
    "ligneMainOeuvreLocaleQte",
    "ligneCompteProrataCU",
    "ligneAleasCU",
    "ligneMainOeuvreDeplaceeCU",
    "ligneMainOeuvreDeplaceeQte",
    "ligneMainOeuvreInterimCU",
    "ligneMainOeuvreInterimQte",
    "ligneEssaisCU",
    "ligneEssaisQte",
    "ligneEtudesCU",
    "ligneEtudesQte",
    "ligneTotalFraisChantierCU",
    "ligneTotalMaitriseCU",
    "ligneDiversCU",
    "ligneOutillageCU",
    "ligneTotalMaitriseEtudeCU",
    "ligneTotalMaitriseChargeAffaireCU",
];