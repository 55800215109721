<template>
    <b-card :title="showHeader ? title : ''" :style="{fontSize, maxWidth}" class="decmontant">
        <b-row>
            <table class="w-100">
                <!-- bordure bottom du tableau dec -->
                <thead :class="showHeader ? 'border-bottom-dark' : ''">
                    <tr class="text-primary" ref="row-header" :style="getForcedHeightStyle(`row-header`)">
                        <th v-if="showLabelColumn"><span v-if="showHeader">PRIX VENTE INITIAL</span></th>
                        <th class="text-center" v-if="showUnitColumn"><span v-if="showHeader">Unite</span></th>
                        <th :style="{maxWidth: '15%'}"><span v-if="showHeader">QTE</span></th>
                        <th><span v-if="showHeader">C.U. ou Total</span></th>
                        <th class="text-right"><span v-if="showHeader">Montant</span></th>
                        <th v-for="(extraColumn, index) in extraColumns" :key="`extracol_${index}`" :style="extraColumn.style" class="text-right">
                            {{extraColumn.header}}
                        </th>
                    </tr>
                </thead>
                <tbody  :style="{fontSize}">
                    <template v-for="(value, blocIndex) in values">
                        <tr :key="`blocheader-${blocIndex}`" :ref="`row-header-${blocIndex}`" :style="getForcedHeightStyle(`row-header-${blocIndex}`)">
                            <td v-if="showLabelColumn"><b>{{value.title}}</b></td>
                            <td :colspan="totalLabelColspan +1" class="text-right"><b>{{formatNumber(value.fields.hasOwnProperty('total') ? value.fields.total : getTotalBloc(value.fields))}}</b></td>
                            <td v-for="(extraColumn, uselessIndex) in extraColumns" :key="`fillerheader_${uselessIndex}`" :style="extraColumn.style" class="text-right">
                                <span v-if="value.fields.hasOwnProperty('extras')">
                                    <b>{{formatNumber(value.fields.extras[extraColumn.target].value, value.fields.extras[extraColumn.target].prefix)}}</b>
                                </span>
                            </td>
                        </tr>

                        <tr v-for="(field, ligneIndex) in value.fields" :key="`ligne-${blocIndex}-${ligneIndex}`" :ref="`row-${blocIndex}-${ligneIndex}`" :style="getForcedHeightStyle(`row-${blocIndex}-${ligneIndex}`)">
                            <template v-if="field.id != 'ligneEngage' || showEngage">
                                <template v-if="!showEngagePlaceHolder">
                                    <!-- Bordure right du tableau dec -->
                                    <td class="border-right-dark" v-if="showLabelColumn">{{field.title}}</td>
                                    <td class="text-center text-middle" v-if="showUnitColumn"><span v-if="field.hasOwnProperty('unit')">{{field.unit}}</span></td>
                                    <td class="text-middle">
                                        <b-form-group v-if="field.hasOwnProperty('qty') && showInputs">
                                            <validation-provider #default="{ errors }" :name="field.qty.field" :rules="validationEnabled ? formRules : ''">
                                                <b-form-input
                                                    type="number"
                                                    :id="field.qty.field"
                                                    :value="field.qty.value"
                                                    @change="$emit('update', field.qty.field, $event)"
                                                    placeholder="0"
                                                    :style="{maxWidth: '100px', fontSize, height: '30px'}"
                                                    min="0" 
                                                    number
                                                    :disabled="disableFields || field.id == 'ligneEngage'"
                                                    :class="errors.length > 0 ? 'is-invalid' : ''"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                        <span v-else-if="field.hasOwnProperty('qty') && !value.hideQty">{{parseFloat(field.qty.value).toFixed(2)}}</span>
                                        <span v-else>Coût total</span>
                                    </td>
                                    <td>
                                        <b-form-group v-if="showInputs">
                                            <validation-provider #default="{ errors }" :name="field.cu.field" :rules="validationEnabled ? formRules : ''">
                                                <b-input type="number"
                                                    :id="field.cu.field"
                                                    class=" text-right"
                                                    :class="errors.length > 0 ? 'is-invalid' : ''"
                                                    :value="field.cu.value"
                                                    @change="$emit('update', field.cu.field, parseFloat($event))"
                                                    placeholder="0,00"
                                                    :style="{fontSize, height: '30px'}"
                                                    :disabled="disableFields  || field.id == 'ligneEngage'"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                        <span v-else>{{formatNumber(parseFloat(field.cu.value))}}</span>
                                    </td>
                                    <td class="text-right">{{formatNumber(field.hasOwnProperty('total') ? field.total : getTotalQty(field))}}</td>
                                    <td class="text-right" v-for="(extraColumn, extraIndex) in extraColumns" :key="`extratd_${extraIndex}`" :style="extraColumn.style">
                                        {{formatNumber(field.extras[extraColumn.target].value, field.extras[extraColumn.target].prefix)}}
                                    </td>
                                </template>
                                <td v-else colspan="7777" />
                            </template>
                        </tr>

                        <!-- <tr :key="`extras-row-${blocIndex}`" :ref="`extras-row-${blocIndex}`" :style="getForcedHeightStyle(`extras-row-${blocIndex}`)">
                            <slot :name="`extras-row-${blocIndex}`">
                                <td colspan="7777"></td>
                            </slot>
                        </tr> -->
                    </template>

                </tbody>
                <tfoot class="border-top-primary" v-if="showTotalRow" :style="{fontSize}">
                    <tr class="text-primary" ref="row-footer" :style="getForcedHeightStyle('row-footer')">
                        <th :colspan="totalLabelColspan" v-if="showLabelColumn"><b>Total général du débours</b></th>
                        <th :colspan="totalLabelColspan +1" class="text-right"><b>{{formatNumber(footerTotal != null ? footerTotal : getTotal)}}</b></th>
                        <th v-for="(extraColumn, uselessIndex) in extraColumns" :key="`fillerfooter_${uselessIndex}`" :style="extraColumn.style" class="text-right">
                            <b v-if="extraColumn.hasOwnProperty('total')">{{formatNumber(extraColumn.total, extraColumn.prefix)}}</b>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </b-row>
    </b-card>
</template>


<script>
    import {formatNumber} from "@/services/utils"

    import { ValidationProvider } from 'vee-validate'

    export default {
        components: {
            ValidationProvider
        },
        props: {
            target: {default: null},
            values: {required: true},
            title: {required: true},
            validation: {default: false},
            showInputs: {default: true},
            disableFields: {default: false},
            showHeader: {default: true},
            showLabelColumn: {default: true},
            showUnitColumn: {default: true},
            showTotalRow: {default: false},
            forcedHeights: {default: null},
            fontSize: {default: '1rem'},
            maxWidth: {default: null},
            emitTotal: {default: false},
            extraColumns: {default: () => []},
            extraTotaux: {default: () => []},
            formRules: {default: ''},
            validationEnabled: {default: false},
            footerTotal: {default: null},
            countOneIfZero: {default: false},
            showEngage: {default: false},
            showEngagePlaceHolder: {default: false},
            bus: {default: null}
        },

        setup(props, {root}) {
            return {
                formatNumber
            }
        },

        beforeMount() {
            if(this.bus != null) {
                this.bus.$on("resize", this.resize)
            }
        },

        computed: {
            getTotal() {
                let total = this.values.reduce((accumulator, {fields}) => accumulator + this.getTotalBloc(fields), 0);
                if(this.emitTotal)
                    this.$emit('total', total);
                return total
            },
            totalLabelColspan() {
                let colspan = 4
                if(!this.showUnitColumn)
                    colspan --;
                if(!this.showLabelColumn)
                    colspan--;
                return colspan
            }
        },

        methods: {
            resize() {
                this.$nextTick(() => {
                    let heights = Object.entries(this.$refs).reduce((accumulator, [key, value]) => {
                        if(!key.includes("row"))
                            return accumulator;
                        let height = Array.isArray(value) ? value[0].clientHeight : value.clientHeight
                        return {...accumulator, [key] : height}
                    }, {})
                    this.$emit("mounted", heights);
                    console.log("emitted", heights)
                })
            },

            getTotalQty(field) {
                let currentCU = isNaN(parseFloat(field.cu.value)) ? 0 : parseFloat(field.cu.value);
                let currentQty = !field.hasOwnProperty("qty") ? 1 : (isNaN(parseFloat(field.qty.value)) ? 0 : parseFloat(field.qty.value));
                if (this.countOneIfZero && currentQty == 0)
                    currentQty = 1;
                return currentCU * currentQty;
            },

            getTotalBloc(fields) {
                return fields.reduce((accumulator, field) => {
                    return accumulator + this.getTotalQty(field)
                }, 0)
            },

            getForcedHeightStyle(key) {
                if(this.forcedHeights == null || !this.forcedHeights.hasOwnProperty(key))
                    return {};
                else {
                    let res = {height: `${this.forcedHeights[key]}px`}
                    return res;
                }
            }
        }
    }
</script>


<style scoped>
    .text-middle {
        vertical-align: middle !important;
    }
</style>